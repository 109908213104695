<template>
    <v-card>
        <v-card-title>
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <div>
                <div>
                    <v-toolbar
                        flat
                        :loading="loading"
                        color="#eeeeee"
                        style="border: #e0e0e0 1px solid;"
                    >
                        <v-btn
                            outlined
                            class="mr-4"
                            color="grey darken-2"
                            @click="setToday"
                        >
                            Today
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="prev"
                        >
                            <v-icon small>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="next"
                        >
                            <v-icon small>
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                            {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-select
                            v-model="type"
                            :items="types"
                            hide-details
                            solo
                            outline
                            flat
                            placeholder="Status"
                            style="max-width: 150px"
                            class="mx-4"
                        />
                        <v-text-field
                            v-model="project"
                            hide-details
                            placeholder="Project"
                            outline
                            flat
                            solo
                        />
                    </v-toolbar>
                </div>
            </div>
            <div>
                <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    :event-overlap-mode="'stack'"
                    :event-overlap-threshold="0"
                    :event-more="false"
                    type="month"
                    :event-height="-1"
                    @click:event="showEvent"
                    @change="setEvents"
                >
                    <template slot="dayMonth" slot-scope="{ day }">
                        <div class="day">dayHeader slot {{ day }}</div>
                    </template>
                    <template #event="{event}">
                        <div class="pa-2 mt-2">
                            <p class="my-0 text-wrap text-body-1">
                                {{ event.name }}
                            </p>
                            <p class="my-0 text-wrap text-body-2">
                                {{ event.purchase.project.reference }} -
                                {{ event.purchase.project.name }}
                            </p>
                        </div>
                        <div class="d-flex justify-end pt-0 px-1 pb-1 mb-n2">
                            <p class="pt-5 text-body-1">
                                {{
                                    formatNumber(calculateTotal(event.purchase))
                                }}
                            </p>
                        </div>
                    </template>
                </v-calendar>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'

export default {
    name: 'PurchaseSchedule',
    props: {
        purchases: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        project: '',
        focus: '',
        events: [],
        types: [
            'ALL',
            'inProgress',
            'onHold',
            'pricing',
            'preApproval',
            'approval',
            'purchasing',
            'inTransit',
            'received',
            'rejected',
        ],
        type: 'ALL',
        loading: false,
        colors: Object.freeze({
            inProgress: 'primary',
            onHold: 'green',
            pricing: '#3293e6',
            preApproval: '#FF9800',
            approval: '#27BC4F',
            purchasing: 'primary',
            inTransit: '#ff7800',
            received: '#00a135',
            rejected: 'red',
        }),
    }),
    watch: {
        filterPurchases() {
            this.setEvents()
        },
    },
    computed: {
        filterPurchases() {
            let conditions = []
            if (this.type) {
                conditions.push(this.filterByType)
            }
            if (this.project) {
                conditions.push(this.filterByProject)
            }
            if (conditions.length > 0) {
                return this.purchases.filter(purchase => {
                    return conditions.every(condition => {
                        return condition(purchase)
                    })
                })
            }
            return this.purchases
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        filterByType(purchase) {
            if (this.type == 'ALL') {
                return true
            } else return this.type == purchase.status
        },
        filterByProject(purchase) {
            let projectName =
                `${purchase.project.reference}`.toLowerCase() +
                ' ' +
                purchase.project.name.toLocaleLowerCase()
            return projectName.includes(this.project.toLowerCase())
        },
        calculateTotal(request) {
            let calculatedTotal = 0
            request.items = request.items ? request.items : []
            request.items.forEach(i => {
                i.quotes = i.quotes ? i.quotes : []
                i.quotes.forEach(q => {
                    if (q.able) {
                        calculatedTotal += i.quantity * q.price
                    }
                })
            })
            return calculatedTotal
        },
        setToday() {
            this.focus = ''
        },
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        formatNumber(value) {
            return new Intl.NumberFormat('de-DE').format(value)
        },
        setEvents() {
            let events = []
            for (const purchase of this.filterPurchases) {
                if (purchase.dueDate) {
                    const date =
                        typeof purchase.dueDate == 'string'
                            ? purchase.dueDate.slice(0, 10)
                            : moment
                                  .unix(
                                      purchase.dueDate._seconds ||
                                          purchase.dueDate.seconds
                                  )
                                  .format('YYYY-MM-DD')
                    events.push({
                        name: `(${purchase.number}) ` + purchase.type,
                        start: date,
                        end: date,
                        color: this.colors[purchase.status],
                        timed: true,
                        purchase,
                    })
                }
            }
            this.events = events
        },
        async showEvent({ event }) {
            try {
                console.log(event.purchase.number)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
